import RootLayout from "@app/pages/Layout";
import BssoLogin from "@app/pages/bsso/login";
import BssoLogout from "@app/pages/bsso/logout";
import MediaLayout from "@app/pages/main/media/edit/LayoutMedia";
import React from "react";
import { RouteObject, createBrowserRouter } from "react-router-dom";

const Home = React.lazy(() => import("@app/pages/main/home"));

const MediaList = React.lazy(() => import("@app/pages/main/media"));
const MediaMain = React.lazy(() => import("@app/pages/main/media/edit/sections/MediaMainSection"));
const MediaEditCrawlers = React.lazy(() => import("@app/pages/main/media/edit/sections/MediaCrawlerSection"));
const MediaEditPages = React.lazy(() => import("@app/pages/main/media/edit/sections/MediaPagesSection"));

const routes: RouteObject[] = [
	{
		path: "/",
		element: <RootLayout />,
		children: [
			{
				index: true,
				element: <Home />
			},
			{
				path: "media",
				element: <MediaList />
			},
			{
				path: "media/:mediaId",
				element: <MediaLayout />,
				children: [
					{
						index: true,
						element: <MediaMain />
					},
					{
						path: "crawler/:crawlerId?",
						element: <MediaEditCrawlers />
					},
					{
						path: "pages",
						element: <MediaEditPages />
					}
				]
			}
		]
	},
	{
		path: "/bsso",
		children: [
			{
				path: "login",
				element: <BssoLogin />
			},
			{
				path: "logout",
				element: <BssoLogout />
			}
		]
	}
];

const router = createBrowserRouter(routes);

export default router;
