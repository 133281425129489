import { useFujiPost } from "@app/hooks/useFuji";
import type { CreateSessionRequest } from "@fuji/session";
import React from "react";
import { useSearchParams } from "react-router-dom";

import { Loader } from "@launchmetrics/lm-ui";

function BssoLogin() {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [searchParams, setSearchParams] = useSearchParams();

	const createSessionRequest: CreateSessionRequest = {
		code: searchParams.get("code") as string,
		user: Number(searchParams.get("user")),
		user_name: searchParams.get("user_name") as string,
		user_email: searchParams.get("user_email") as string,
		ts: Number(searchParams.get("ts")),
		permissions: searchParams.get("permissions") as string,
		roles: searchParams.get("roles") as string
	};

	React.useEffect(() => {
		const login = async () => {
			try {
				await useFujiPost<CreateSessionRequest, unknown>("/session", createSessionRequest);
				window.location.href = searchParams.get("next") as string;
			} catch (error: unknown) {
				if (error instanceof Error) {
					throw new Error(`Error creating session: ${error.message}`);
				}
			}
		};

		login();
	}, [searchParams]);

	return <Loader />;
}

export default BssoLogin;
