import Session from "@app/class/Session";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Loader } from "@launchmetrics/lm-ui";

const session = new Session();

function AuthProvider({ children }: { children: React.ReactNode }) {
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		const authenticate = async () => {
			const regex = RegExp("^(/login|/logout|/error)");
			if (!regex.test(location.pathname)) {
				const userStatus = await session.getAuthenticationStatus();
				if (!userStatus.isAuthenticated) {
					const hostname = window.location.origin;
					const nextRoute = `${hostname}${location.pathname}`;
					const connectRoute = `${hostname}/bsso/login`;

					const bssoRoute = `${process.env.REACT_APP_BSSO_URL}/?next=${encodeURIComponent(
						nextRoute
					)}&connect=${encodeURIComponent(connectRoute)}`;

					window.location.href = bssoRoute;
				} else {
					if (userStatus.response && session.hasPermission("access")) {
						setLoading(false);
					} else {
						navigate("/401");
					}
				}
			} else {
				setLoading(false);
			}
		};

		authenticate();
	}, [location]);

	return loading ? <Loader styleProps={{ color: "#4DF7B9" }} /> : <>{children}</>;
}

export default AuthProvider;
