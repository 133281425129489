import FujiApi from "@app/lib/FujiApi";
import type { GetSessionResponse } from "@fuji/session";

const fujiApi = new FujiApi();

class Session {
	async getAuthenticationStatus() {
		try {
			const response = await fujiApi.get<never, GetSessionResponse>("/session");
			this.store(response);
			return { isAuthenticated: true, response };
		} catch (error: unknown) {
			return { isAuthenticated: false, response: null };
		}
	}

	store(user: GetSessionResponse): void {
		localStorage.setItem("session", JSON.stringify(user));
	}

	getUserName(): string {
		return this.get().user.name;
	}

	hasPermission(permission: string): boolean {
		const orangePermission = `orange.${permission}`;
		const session = this.get();
		return session.permissions.includes(orangePermission);
	}

	delete(): void {
		localStorage.removeItem("session");
	}

	private get(): GetSessionResponse {
		const session = localStorage.getItem("session");
		if (!session) {
			throw new Error("User session does not exist");
		}

		return JSON.parse(session);
	}
}

export default Session;
