import Session from "@app/class/Session";
import FujiApi from "@app/lib/FujiApi";
import React from "react";

import { Typography } from "@launchmetrics/lm-ui";

const fujiApi = new FujiApi();
const session = new Session();

function BssoLogout() {
	React.useEffect(() => {
		const logout = async () => {
			try {
				await fujiApi.delete("/session");
				session.delete();
			} catch (error: unknown) {
				throw new Error(`Error deleting session: ${error}`);
			}
		};

		logout();
	}, []);

	return (
		<>
			<Typography as="h1" variant={"headingXlRegular"} align="center" children="Logged Out" />
			<Typography as="h1" variant={"headingXlRegular"} align="center" children="Disconnected" />
			<Typography as="h5" variant={"headingXlRegular"} align="center" children="Logged out correctly! See you soon" />
			<Typography as="h5" variant={"headingXlRegular"} align="center" children={<a href="/">Go App!</a>} />
		</>
	);
}

export default BssoLogout;
