import FujiApi from "@app/lib/FujiApi";

const FUJI = new FujiApi();

async function useFujiGet<QueryParams, Response>(path: string, params?: QueryParams): Promise<Response> {
	return await FUJI.get<QueryParams, Response>(path, params);
}

async function useFujiPost<Request, Response>(path: string, data: Request): Promise<Response> {
	return await FUJI.post<Request, Response>(path, data);
}

function useFujiPut<Request, Response>(path: string, data: Request) {
	return FUJI.put<Request, Response>(path, data);
}

export { useFujiGet, useFujiPost, useFujiPut };
