export const sections = [
	{
		name: "Media",
		url: "/media",
		childrens: [
			{
				name: "Media Search",
				url: "/media"
			}
		]
	}
];
