/******************************************************
 *
 * Tiny class to wrap fuji calls, their only purpose
 * is to provide base url and basic http methods
 *
 * All rights reserved (c) Launchmetrics
 ******************************************************/
import axios, { AxiosInstance } from "axios";

const BASE_URL = process.env.REACT_APP_API_URL as string;

export default class FujiApi {
	private _axios: AxiosInstance;

	constructor() {
		this._axios = axios.create({
			baseURL: BASE_URL,
			withCredentials: true
		});
	}

	async get<QueryParams, Response>(path: string, params?: QueryParams): Promise<Response> {
		return <Response>(await this._axios.get(path, { params })).data;
	}

	async post<Request, Response>(path: string, data: Request): Promise<Response> {
		return <Response>(await this._axios.post(path, data)).data;
	}

	async put<Request, Response>(path: string, data: Request): Promise<Response> {
		return <Response>(await this._axios.put(path, data)).data;
	}

	async delete<Response>(path: string): Promise<Response> {
		return <Response>(await this._axios.delete(path)).data;
	}
}
