import "@app/components/common/content/styles.scss";
import React from "react";

type ComponentProps = {
	children: React.ReactNode;
};

function Content(props: ComponentProps) {
	return <div className="content">{props.children}</div>;
}

export default Content;
